import gql from "graphql-tag"

const GET_NEXT_PREVIOUS_ITEMS_QUERY = gql`
  query MyQuery($id: ID!) {
  cASESTUDY(id: $id, idType: SLUG) {
    id
    previousCASESTUDY {
      node {
        id
        title
        slug
        content
        casestudies {
          images {
            image1 {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                  width
                }
              }
            }
          }
        }
      }
    }
    nextCASESTUDY {
      node {
        id
        title
        slug
        content
        casestudies {
          images {
            image1 {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                  width
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default GET_NEXT_PREVIOUS_ITEMS_QUERY

