import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SeoBasic from "../../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ReactHtmlParser from "react-html-parser"
import BannerCasestudy from "../../components/bannerCasestudy"
import getImage from "../../functions/getImage"
import GET_NEXT_PREVIOUS_ITEMS_QUERY from "../../hooks/nextprevious"
import { useQuery } from "react-apollo"
// import MailingList from "../../components/mailingList"
import Arrow from "../../components/symbols/arrow"

export const query = graphql`
  query($slug: String) {
    wpCasestudy(casestudies: {}, slug: {eq: $slug}) {
    slug
    title
    content
    casestudies {
      images {
        image1 {
          mediaDetails {
            sizes {
              name
              sourceUrl
              width
            }
          }
        }
        image2 {
          id
          mediaDetails {
            sizes {
              name
              sourceUrl
              width
            }
          }
        }
      }
      section1 {
        text
        title
      }
      section2 {
        text
        title
      }
      section3 {
        text
        title
      }
      section4 {
        text
        title
      }
      sectionquote {
        author
        text
      }
    }
  }
  seoPage:wpCasestudy(slug: {eq: $slug}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
  }
  }
`


const GetNextandPreviousItems = ({slug}) => {

  // Fetching the search data from WPGraphQL via Apollo.
  const { data: queriedData } = useQuery(GET_NEXT_PREVIOUS_ITEMS_QUERY, {
    variables: { id:slug },
  })
  
  return (

    <React.Fragment>
      
      <div className="arrow prev">
        {queriedData && queriedData.cASESTUDY.nextCASESTUDY &&

          <a className="link" rel="noreferrer" href={`/case-studies/${queriedData.cASESTUDY.nextCASESTUDY.node.slug}`}>
          
          <svg className="arrownext" xmlns="http://www.w3.org/2000/svg" width="40.459" height="69.045" viewBox="0 0 40.459 69.045">
            <path id="Path" d="M40.459,6.172,34.288,0,0,34.522,34.323,69.045l6.137-6.172L12.273,34.522Z"/>
          </svg>

          <div>{queriedData.cASESTUDY.nextCASESTUDY.node.title}</div>
          
          </a>
        
      }

      </div>

      <div className="arrow next">
      
       {queriedData && queriedData.cASESTUDY.previousCASESTUDY &&

        <a className="link" rel="noreferrer" href={`/case-studies/${queriedData.cASESTUDY.previousCASESTUDY.node.slug}`}>
        
        <div>{queriedData.cASESTUDY.previousCASESTUDY.node.title}</div> 

        <svg className="arrowprevious" xmlns="http://www.w3.org/2000/svg" width="40.459" height="69.045" viewBox="0 0 40.459 69.045">
          <path id="Path" d="M40.459,6.172,34.288,0,0,34.522,34.323,69.045l6.137-6.172L12.273,34.522Z"/>
        </svg>
   
        </a>
       
      }
      </div>

    </React.Fragment>

  )
}


const GetNextItem = ({slug}) => {

  // Fetching the search data from WPGraphQL via Apollo.
  const { data: queriedData } = useQuery(GET_NEXT_PREVIOUS_ITEMS_QUERY, {
    variables: { id:slug },
  })

  // console.log(queriedData)

  return (

    <div className="cs-next-up">
      
       {queriedData && queriedData.cASESTUDY.nextCASESTUDY &&

        <React.Fragment>
          <a className="read-more" rel="noreferrer" href={`/case-studies/${queriedData.cASESTUDY.nextCASESTUDY.node.slug}`}>
          <div className="column1">
          <div className="header"><h2>Next up</h2><Arrow /></div>
          {ReactHtmlParser(queriedData.cASESTUDY.nextCASESTUDY.node.content)}
          </div>
          </a>
          {queriedData.cASESTUDY.nextCASESTUDY.node.content &&
          <div className="column2">
          <a className="read-more" rel="noreferrer" href={`/case-studies/${queriedData.cASESTUDY.nextCASESTUDY.node.slug}`}>
          <h2>{queriedData.cASESTUDY.nextCASESTUDY.node.title}</h2>
          {ReactHtmlParser(queriedData.cASESTUDY.nextCASESTUDY.node.content)}
           <div className="link">READ CASE STUDY<Arrow /></div>
          </a>
          </div>
          }
          {queriedData.cASESTUDY.nextCASESTUDY.node.casestudies.images &&
          <div className="column3" style={{ backgroundImage: `url("${getImage(queriedData.cASESTUDY.nextCASESTUDY.node.casestudies.images.image1.mediaDetails.sizes)}")` }}>
          </div>
          }
        </React.Fragment>
      
      }
      </div>

  )
}

const WpCasestudy = ({
  data: {
    wpCasestudy: { slug, title, content, casestudies }, seoPage
  },
}) => {

  const [loadImageSection, setLoadImageSection] = useState(false)
  const {section1, section2, section3, section4, sectionquote} = casestudies
  const bannerText = content

  useEffect(() => {

      if (typeof window !== "undefined") {
        
        setLoadImageSection(true)

        console.log('window loaded')
      }
  }, [])

  return (
    <Layout>
      {seoPage ?
        <Seo post={seoPage} />
      :
        <SeoBasic title={title} />
      }

      {loadImageSection &&
      <>
      <div className="cs-nextprevious-holder">

        <GetNextandPreviousItems slug={slug} />

      </div>

      <div>
        {casestudies.images.image1 &&
        <BannerCasestudy img={getImage(casestudies.images.image1.mediaDetails.sizes)} text={bannerText} imgclass="bannerImg casestudies" />
        }
      </div>

       {section2 &&
      <div className="cs-section-2">
      <h2>{section2.title}</h2>
      {ReactHtmlParser(section2.text)}
      </div>
      }

      {section1 &&
        <div className="cs-section-1">
        <h2>{section1.title}</h2>
        {ReactHtmlParser(section1.text)}
        </div>
      }

       {section2 &&
      <div className="cs-section-2 mobile">
      <h2>{section2.title}</h2>
      {ReactHtmlParser(section2.text)}
      </div>
      }

      {section4 &&
      <div className="cs-section-4-holder">
      
        {casestudies.images.image2 &&
        <div className="cs-section-image" style={{ backgroundImage: `url("${getImage(casestudies.images.image2.mediaDetails.sizes)}")` }}></div>
        }

         {section3 &&
        <div className="cs-section-3 mobile">
       
          <h2>{section3.title}</h2>
          {ReactHtmlParser(section3.text)}

        </div>
      }

        <div className="cs-section-4">
        <h2>{section4.title}</h2>
        {ReactHtmlParser(section4.text)}
         </div>

      </div>
      }

      {section3 &&
        <div className="cs-section-3">
       
        <h2>{section3.title}</h2>
        {ReactHtmlParser(section3.text)}
         
        </div>
      }

      {section4 &&

        <div className="cs-section-4 mobile">
        <h2>{section4.title}</h2>
        {ReactHtmlParser(section4.text)}
        </div>

      }

      {sectionquote &&
      <div className="cs-section-quote-holder">
        <div className="cs-section-quote">
        {sectionquote.text}
        </div>
        <div className="cs-section-author">
        {sectionquote.author}
        </div>
      </div>
      }

      <div className="mailingList">

        <div className="cs-next-up-holder">

          <GetNextItem slug={slug} />

        </div>

        {/*<MailingList styles="mailingList-left no-yellow-block" />*/}
      </div>
      </>
      }
  
    </Layout>
  )
}


export default WpCasestudy